.project-carousel  {
    margin-bottom: 3rem;
}

.project-carousel .carousel-item {
    width: 100%;
}

.project-carousel .carousel-item img {
    max-width: 100%;
    /*width: auto;*/
    max-height: 85vh;
    display: block;
    margin-left: auto;
    margin-right: auto;

}

.project-carousel .carousel-indicators [data-bs-target] {
    width: 8px !important;
    height: 8px !important;
    border-radius: 50%;
}

div {
    white-space: pre-line;
}