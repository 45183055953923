@font-face {
  font-family: aileron;
  src: url("fonts/aileron/aileron_regular.woff");
}

@font-face {
  font-family: fz;
  src: url("fonts/fz/FZYouHJVFWTWH1.woff");
}


* {
  font-family: aileron, serif;
}

.cn {
  font-family: aileron, serif !important;
}

body {
  font-family: aileron, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-content {
  margin-bottom: 3rem;
  margin-top: 10vh;
}

.page-content a {
  color: #2f3439;
  text-decoration: none;
}

.page-content a:hover {
  color: #ff0000;
}

.carousel-control-prev-icon {
  background-image: url('media/prev.svg') !important;
}

.carousel-control-next-icon {
  background-image: url('media/next.svg') !important;
}