

.top-menu, .top-menu-container {
    background-color: rgba(255, 255, 255, 1) !important;
}

.dropdown-divider {
    display: none;
}

@media only screen and (min-width: 766px) {
    nav {
        /*height: 45px !important;*/
        height: 2.5rem !important;
    }
}

@media only screen and (max-width: 766px) {

    .dropdown-divider {
        display: block;
    }

    .top-menu, .top-menu-container, .navbar-collapse {
        background-color: rgba(248, 249, 250, 1) !important;
    }

    .navbar-collapse {
        margin-top: 1rem;
    }
}