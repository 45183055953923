footer {
    background: rgba(248, 249, 250, 0.7) !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.footer-social img {
    width: 2rem;
    height: 2rem;
    opacity: 1;
}

.footer-social img:hover {
    opacity: 0.8;
}


.footer-social a {
    text-transform: none;
    text-decoration: none !important;
    color: #6c757d;
}