
.project-card {
    background-color: transparent;
    position: relative;
}

.project-card img {
    width: 100%;
}

.project-card-text {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    background: #000000;
}

.project-card-text:hover {
    opacity: 1;
    cursor: pointer;
}

.project-card a:link, .project-card a:visited, .project-card a:hover, .project-card a:active {
    color: white !important;
    text-decoration: none;
}