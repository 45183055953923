.social img {
    width: 2rem;
    height: 2rem;
    opacity: 1;
}

.social img:hover {
    opacity: 0.8;
}

.social img {
    margin-right: 5px;
}