.main-carousel {
    height: 100vh;
}

.main-carousel .carousel-item {
    height: 100vh;
}

.main-carousel .carousel-item img {
    /*width: 100%;*/
}

.main-carousel .carousel-inner img {
    display:block;
    object-fit: cover;
}

.main-carousel-img {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.main-carousel .carousel-indicators [data-bs-target] {
    width: 8px !important;
    height: 8px !important;
    border-radius: 50%;
}

.dark-bg {
    background: #000;
}