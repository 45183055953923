.language {
    outline: none;
    caret-color: transparent !important
}

.language button {
    color: #6c757d!important;
    outline: none !important;
    text-decoration: none;
    border: none;
    background: none !important;
    text-transform: uppercase;
}

.language button:hover, .language button.active {
    cursor: pointer;
    color: #212529!important;
}

@media only screen and (max-width: 766px) {
    .language button:first-child {
        padding-left: 0;
    }
}